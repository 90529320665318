.CalendarDay__selected {
  background: #0f79fe;
  color: white;
  border: 1px solid #0f79fe;
}

.CalendarDay__selected_span {
  background: #0f79fe;
  color: white;
  border: 1px solid #0f79fe;
}

.CalendarDay__selected:hover {
  background: #72cff8;
  color: white;
  border: 1px solid #72cff8;
}

.CalendarDay__selected_span:hover {
  background: #72cff8;
  color: white;
  border: 1px solid #72cff8;
}

.DateInput_input {
  font-weight: 500;
  font-size: 13px;
  line-height: 28px;
  color: #555;
  background-color: #fff;
  width: 80%;
  border: 0;
  border-top: 0;
  border-right: 0;
  border-bottom: 2px solid transparent;
  border-left: 0;
  border-radius: 0;
  padding: 0px;
  text-align: center;
}

.DateRangePickerInput__withBorder {
  border-radius: 3px;
  border: 1px solid #ddd;
}

.DateInput__small {
  width: 100px;
  text-align: center;
  border-radius: 3px;
}

.DateRangePickerInput_arrow {
  display: none;
}
