
.linkedin-input-box{
  border: 0.5px solid #BEBEC5;
  width: 100%;
  border-radius: 6px;
  min-height: 120px;
  padding: 10px;
  width: 100%;
  font-family: "Poppins","sans-serif" !important;
  font-size: 14px !important;
  font-weight: normal;
}

.linkedin-input-box p {
  display: block;
  margin: 0;
}

.linkedin-input-box:hover {
  border: 0.5px solid #2C27FE;
}

.linkedin-input-box.quill {
  padding: 0 0;
}
