html {
  height: 100%;  
}

body {
  margin: 0;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height : 100%;
}

#root {
  height: 100%;  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#text_highlight {
  font-size: 18px;
  resize: none;
  box-sizing: border-box;
  overflow: hidden;
  padding: 30px 40px 30px 35px;
  line-height: 2;
  margin: 0 auto;
  max-width: 50em;
  background: #fff;
  position: relative;
  color: transparent; 
}

#text-highlight .highlight_grammar {
  border-bottom: 2px solid #09f;
}

#text-highlight  .highlight_warn {
  background: rgb(245, 245, 171);
  cursor: pointer !important;
  z-index: 40;
}

#text-highlight .highlight_grammar, .highlight_grammar.highlight_warn {
  border-bottom: 2px solid #09f !important;
}

#text-highlight .highlight_bad, .highlight_bad.highlight_warn {
  background: #fdd;
  cursor: pointer !important;
  z-index: 40;
}

#text-highlight .highlight_adverb {
  border-bottom: 2px dotted #c90;
  cursor: pointer !important;
  z-index: 40;
}

#text-highlight  .highlight_hard_word, .highlight_long_word {
  background: rgba(0, 0, 0, 0.14);
  border-bottom: none;
  cursor: pointer !important;
  z-index: 40;
}

#text-highlight .highlight_spelling {
  border-bottom: 2px solid #f00;
}

#text-highlight .highlight_cliche {
  border-bottom: 2px dotted #0c0;
}

#text-highlight .highlight_passive {
  border-bottom: 2px dotted #00c;
}

#text-highlight .highlight_profanity {
  border-bottom: 2px dotted #f00;
}

#text-highlight .highlight_hard_word, .highlight_long_word, .highlight_adverb, .highlight_cliche, .highlight_passive, .highlight_bad, .highlight_warn, .highlight_toggle, .highlight_hedge, .highlight_transition, .highlight_lazy, .highlight_profanity, .highlight_buzzwords, .highlight_stopwords {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  margin-bottom: 3px;
  padding: 3px 0;
  z-index: 50;
  position: relative;
}

.tooltiptext {
  display: flex;
  position: absolute;
  z-index: -1;
  visibility: hidden;
  right: calc(10% - 10px);
  top: -8px;
  opacity: 0;
  width: 200px;
  padding: 2px;
  border-style: solid;
  border-color: transparent transparent #bfbfbf transparent;
  transition-duration: 0.3s;
  transition-property: transform;
  box-shadow: 0 2px 7px 0px rgba(0,0,0,.1);
  text-align: center;
  overflow: visible;
}

.highlight_hard_word:hover .tooltiptext {
  opacity: 1;
  z-index: 9999999;
  visibility: visible;
  transform: translate(0, -120px);
  background-color: white;
  transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
  text-align: center;
}

.highlight_warn:hover .tooltiptext {
  opacity: 1;
  z-index: 9999999;
  visibility: visible;
  transform: translate(0, -120px);
  background-color: white;
  transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
  text-align: center;
}

.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}
.scrollbar-hidden {
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */
}