.flatpickr-day.selected {
  background-color: #4160FF !important;
  border-color: #4160FF !important;
}
.flatpickr-day.today:hover {
  color: #404848 !important;
  background-color: #E5FDFF !important;
  border-color: #E5FDFF !important;
}

.flatpickr-day.highlighted {
  color: #DE1C22  !important;
  cursor: pointer !important;
}

.flatpickr-day.highlighted:hover {
  color: #9F1B1F!important; 
}







